import ApiService from '../../assets/js/api.service';
import {
  ACT_GET_SUPPORT_APPLY_LIST,
  ACT_GET_SUPPORT_BUSINESS_LIST,
  ACT_GET_SUPPORT_NOTICE_LIST,
  ACT_GET_SUPPORT_BUSINESS,
  ACT_GET_MSS_SPRT_BIZ_LIST,
  ACT_GET_MSS_SPRT_BIZ_SEARCH_LIST,
  ACT_GET_SUPT_BIZ_LIST,
  ACT_GET_MSS_SPRT_BIZ_HIST_INFO_LIST,
  ACT_GET_SPRT_BIZ_TREE_MENU,
  ACT_GET_SPRT_BIZ_INTRD,
  ACT_GET_SPRT_BIZ_INTRD_EXM_LIST,
  ACT_GET_SPRT_BIZ_INTRD_EXM
} from '../_act_consts';
import {
  MUT_CLOSE_NOTICE_MODAL, 
  MUT_SHOW_NOTICE_MODAL, 
  MUT_SPRT_BIZ_TREE_MENU_LIST, 
  MUT_SPRT_BIZ_TREE_MENU_VISIBLE
} from '../_mut_consts';

const state = {
  notice: {
    show: false,
    sprtBizNtcTit: '',
    sprtBizNtcCn: '',
    regDt: 0
  }
  ,intrd:{
    treeMenus: [],
    treeMenuVisible: false,
  } 
}

const getters =  {
  notice (state) {
    return state.notice;
  },
  treeMenus(state) {
    return state.intrd.treeMenus;
  },
  treeMenuVisible(state) {
    return state.intrd.treeMenuVisible;
  }
}

const mutations = {
  [MUT_SHOW_NOTICE_MODAL](state, notice){
    notice.show = true;
    state.notice = notice;
  },
  [MUT_CLOSE_NOTICE_MODAL](state){
    state.notice.show = false;
  },
  [MUT_SPRT_BIZ_TREE_MENU_LIST](state, treeMenus){
    state.intrd.treeMenus = treeMenus;
  },
  [MUT_SPRT_BIZ_TREE_MENU_VISIBLE](state, isVisible){
    state.intrd.treeMenuVisible = isVisible;
  },
  
}

const actions = {
  [ACT_GET_SUPPORT_BUSINESS_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/bizs', params).then(response => resolve(response));
    });
  },
  [ACT_GET_SUPPORT_APPLY_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/aplys', params).then(response => resolve(response));
    });
  },
  [ACT_GET_SUPPORT_NOTICE_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/support/ntcs', params).then(response => resolve(response));
    });
  },
  [ACT_GET_SUPPORT_BUSINESS]({}, sprtBizId){
    return new Promise(resolve => {
      ApiService.get('/v1/app/support/biz', sprtBizId).then(response => resolve(response));
    });
  },
  [ACT_GET_MSS_SPRT_BIZ_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/mss/sprt/biz', params).then(response => resolve(response));
    })
  },
  [ACT_GET_MSS_SPRT_BIZ_SEARCH_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/mss/sprt/biz/srch/list', params).then(response => resolve(response));
    })
  },
  [ACT_GET_SUPT_BIZ_LIST]({}, params) {
    return new Promise(resolve => {
      ApiService.query('/v1/app/supt/biz', params).then(response => resolve(response));
    })
  },
  [ACT_GET_MSS_SPRT_BIZ_HIST_INFO_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/mss/sprt/biz/hist/info/list', params).then(response => resolve(response));
    });
  },
  [ACT_GET_SPRT_BIZ_TREE_MENU]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/sprt/biz/treeMenus', params).then(response => resolve(response));
    });
  },
  [ACT_GET_SPRT_BIZ_INTRD]({}, {intrdId, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/sprt/biz/intrd/${intrdId}`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_SPRT_BIZ_INTRD_EXM_LIST]({}, {intrdId, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/sprt/biz/intrd/${intrdId}/exms`, params).then(response => resolve(response));
    });
  },
  [ACT_GET_SPRT_BIZ_INTRD_EXM]({}, {exmId, params}){
    return new Promise(resolve => {
      ApiService.query(`/v1/app/sprt/biz/intrd/exm/${exmId}`, params).then(response => resolve(response));
    });
  }
}

export const support = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

<template>
	<!-- begin::gachi-footer -->
	<footer class="gachi-footer" id="gachi-footer">
		<div class="footer-family">
			<template v-if="isMobile">
				<swiper class="footer-component swiper" ref="mySwiper" :options="swiperOptions">
					<swiper-slide v-for="(item, idx) in familyItems" :key="idx">
						<div class="footer-family-list">
							<div class="footer-family-item">
								<a :href="item.link" class="footer-family-link" title="새창열림" target="_blank">
									<img :src="require(`../../../assets/mobile/img/common/${item.imageSrc}`)" :alt="item.altText" />
								</a>
							</div>
						</div>
					</swiper-slide>
				</swiper>
			</template>
			<template v-else>
				<ul class="footer-component footer-family-list">
					<li v-for="(item, idx) in familyItems" :key="idx" class="footer-family-item">
						<a :href="item.link" class="footer-family-link" title="새창열림" target="_blank">
							<img :src="require(`../../../assets/img/common/${item.imageSrc}`)" :alt="item.altText" />
						</a>
					</li>
				</ul>
			</template>
		</div>
		<div class="footer-row">
			<div class="footer-logo">
				<a href="https://www.mss.go.kr" class="logo-link" title="새창열림" target="_blank">
					<span class="logo logo-mss">중소벤처기업부</span>
				</a>
				<a href="https://www.kodma.or.kr" class="logo-link" title="새창열림" target="_blank">
					<span class="logo logo-sbdc">한국중소벤처기업유통원</span>
				</a>
			</div>
			<div class="footer-description">
				<p class="text">주소: 서울특별시 양천구 목동동로 309 (주)한국중소벤처기업유통원 | 대표이사: 이태식 | 사업자등록번호: 107-81-53660</p>
				<p class="text second-desc">
					<!-- 고객센터: 1899-4049 | <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1078153660" target="_blank">사업자정보</a> | <router-link :to="{ name: 'Policy' }" target="_blank">이용약관</router-link> | <a :href="`${marketUrl}/mn/cm/mncm010`" target="_blank"><b style="color: blue">개인정보처리방침</b></a> -->
					<!-- 고객센터: 1899-0309 | <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1078153660" target="_blank">사업자정보</a> | <router-link :to="{ name: 'Policy' }" target="_blank">이용약관</router-link> | <router-link :to="{ name: 'PrivacyPolicy' }"><b style="color: blue">개인정보처리방침</b></router-link> -->
					고객센터: 1899-0309 | <router-link :to="{ name: 'Policy' }" target="_blank">이용약관</router-link> | <router-link :to="{ name: 'PrivacyPolicy' }"><b style="color: blue">개인정보처리방침</b></router-link>
				</p>
			</div>
		</div>
	</footer>
</template>

<script>
import { mapGetters } from 'vuex';
import { swiperOptions } from '../../../assets/js/swiper.config';

export default {
	name: 'MainFooter',
	data: () => ({
		swiperOptions: swiperOptions({ spaceBetween: 0, autoplay: false, slidesPerView: 2 }),
		isSlideReady: false,
		familyItems: [
			{ link: 'https://fanfandaero.kr/', imageSrc: 'footer_01_fanfan.png', altText: '판판대로' },
			{ link: 'https://www.gov.kr', imageSrc: 'footer_02_gov24.png', altText: '정부24' },
			//{ link: 'https://yestv.or.kr', imageSrc: 'footer_03_smetv.png', altText: '소상공인방송' },
			{ link: 'http://brandk.kr/main', imageSrc: 'footer_04_brandk.png', altText: '브랜드케이' },
			// {link: 'https://www.mss.go.kr', imageSrc: 'family_05_mss.jpg', altText:'중소벤처기업부'},
			//{ link: 'https://www.kosmes.or.kr', imageSrc: 'footer_06_kosme.png', altText: '중소벤처기업진흥공단' },
			{ link: 'https://www.haengbok.com', imageSrc: 'footer_07_happy.png', altText: '행복한 백화점' },
		],
		marketUrl: `${process.env.VUE_APP_GACHI_MALL_HOST}`,
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
	},
};
</script>

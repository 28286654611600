<template>
  <!-- begin::layer-popup -->
  <div class="layer-popup-container">
    <div class="layer-popup" id="popup-notice">
      <div>
        <div class="popup-wrapper">
          <button type="button" class="btn-close" @click="clickClose">
            <i class="icon-close">팝업닫기</i>
          </button>
          <div class="popup-body">
            <div id="popup-notice-image">
              <swiper-slide v-for="(item, idx) in eduNotice" :key="idx">
                <a :href="item.ntcUrl" style="display:block;"><img :src="item.ntcImgFilePtUrl" :alt="item.ntcTit"></a>
              </swiper-slide>
            </div>
          </div>
          <div class="popup-footer">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" v-model="noShow" id="chk2" />
              <label for="chk2" class="form-check-label">30일간 이창을 열지 않음</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::layer-popup -->
</template>

<script>
import {MUT_CLOSE_EDU_NOTICE_MODAL} from '../../store/_mut_consts';
import {swiperNoticePopOptions} from '../../assets/js/swiper.config';
import {mapGetters} from "vuex";

export default {
  name: 'EduNotificationModal',
  computed: {
    ...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal'])
  },
 data: () => ({
    swiperOptions: swiperNoticePopOptions(),
    eduNotice: [],
    eduNoticeShow : false,
    noShow: false,
  }),
  created(){

    let freeEduItem = {
      "ntcId":28
      , "ntcTit":"마이페이지 이동"
      , "ntcCn":""
      //, "ntcUrl": window.location.protocol + "//" + window.location.host + "/mypage/profile"
      , "ntcUrl": window.location.protocol + "//" + window.location.host + "/mypage2/home"
      , "ntcImgFilePtUrl":"https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/notice/rprs/7693/2023/01/18/9a8a1c8c.png"
      , "ntcImgFileNm":"포털페이지 팝업0118-01.png"
      , "pstgBgngYmd":"20230101"
      , "pstgEndYmd":"20990131"
      , "expsrYn":null
      , "useYn":null
      , "rgtrId":0
      , "regDt":null
      , "mdfrId":0
      , "mdfcnDt":null
    }

    this.eduNotice.push(freeEduItem);
    this.eduNoticeShow = true;
  },
  methods: {
    clickClose(){
      if (this.noShow) {
				this.$cookies.set('edu-notice-popup', 1, '30d');
			}
      this.$store.commit(`notice/${MUT_CLOSE_EDU_NOTICE_MODAL}`);
    },
    clickCloseMonth(){
      this.$cookies.set('edu-notice-popup', 1, '30d');
    }
  }
};
</script>
import {
    ACT_GET_SPACE_GRP_LIST,
    ACT_GET_SPACE_LIST,
    ACT_GET_SPACE_INFO,
    ACT_GET_SPACE_GLRY_AND_DTL_LIST,
    ACT_GET_SPACE_GLRY_DTL_LIST, 
    ACT_GET_SPACE_FAVR,
    ACT_UPDATE_SPACE_FAVR,
    ACT_GET_SPACE_NOTICE_LIST,
    ACT_GET_SPACE_NOTICE_INFO,
    ACT_GET_SPACE_INQ_LIST,
    ACT_DELETE_SPACE_INQ,
    ACT_INSERT_SPACE_INQ,
    ACT_GET_SPACE_ROOM_LIST,
    ACT_GET_SPACE_ROOM_INFO,
    ACT_GET_SPACE_RSVT_LIST,
    ACT_UPDATE_SPACE_RSVT_STATUS,
    ACT_GET_SPACE_RSVT_SCHD_LIST,
    ACT_GET_SPACE_RSVT_CMPY_CHECK,
    ACT_INSERT_SPACE_RSVT_INFO,
    ACT_UPLOAD_SPACE_RSVT_IDENTITY,
  } from '../_act_consts';
  import ApiService from '../../assets/js/api.service';
  import {
    MUT_SET_SPACE_RSVT_TIMES,
    MUT_SET_SPACE_RSVT_BZMNCNFM,
  } from '../_mut_consts';
  
  const state = {
    bzmnCnfm: {
      brno: '',
      cmpyNm: '',
      bzmnCnfmDocCnfmYn: '미확인',
      bzmnCnfmDocUrl: '',
      bzmnCnfmDocNm: '',
      issueDate: '',
      periodStrtDate: '',
      periodEndDate: '',
		},
    rsvtTimes: {
			rsvtDt: '',
      rsvtDayWeek: '',
      rsvtTimeList: [],
		},
  }

  const getters = {
    //ctgrItems: state => state.ctgrItems,
    rsvtTimes(state){
      return state.rsvtTimes;
    },
    bzmnCnfm(state){
      return state.bzmnCnfm;
    },
  }
  
  const mutations = {
    [MUT_SET_SPACE_RSVT_TIMES](state, rsvtTimes){
      state.rsvtTimes = rsvtTimes;
    },
    [MUT_SET_SPACE_RSVT_BZMNCNFM](state, bzmnCnfm){
      state.bzmnCnfm = bzmnCnfm;
    },
  }
  
  const actions = {
    [ACT_GET_SPACE_GRP_LIST]({}, params){
      return new Promise(resolve => {
        ApiService.query('/v1/app/space/grp/lst', params).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_LIST]({}, params){
      return new Promise(resolve => {
        ApiService.query('/v1/app/space/lst', params).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_INFO]({}, spaceId){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/${spaceId}`, {}).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_GLRY_AND_DTL_LIST]({}, params){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/glry/glryAndDtlList`, params).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_GLRY_DTL_LIST]({}, params){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/glry/${params.spaceId}/dtl/${params.glryId}`, params).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_FAVR]({}, spaceId){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/${spaceId}/favr`, {}).then(response => resolve(response));
      });
    },
    [ACT_UPDATE_SPACE_FAVR]({ }, {spaceId, params}) {
      return new Promise(resolve => {
        ApiService.put(`/v1/app/space/${spaceId}/favr`, params, true).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_NOTICE_LIST]({}, params){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/rsvt/ntc/lst`, params).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_NOTICE_INFO]({}, params){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/rsvt/ntc/${params.ntcId}`, {}).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_INQ_LIST]({}, params){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/rsvt/inq/lst`, params).then(response => resolve(response));
      });
    },
    [ACT_DELETE_SPACE_INQ]({ }, params) {
      return new Promise(resolve => {
        ApiService.put(`/v1/app/space/rsvt/inq/del`, params, true).then(response => resolve(response));
      });
    },
    [ACT_INSERT_SPACE_INQ]({ }, params) {
      return new Promise(resolve => {
        ApiService.post(`/v1/app/space/rsvt/inq`, params, true).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_ROOM_LIST]({}, params){
      return new Promise(resolve => {
        ApiService.query('/v1/app/space/rsvt/roomList', params).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_ROOM_INFO]({}, params){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/rsvt/roomInfo`, params).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_RSVT_LIST]({}, params){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/rsvt/lst`, params).then(response => resolve(response));
      });
    },
    [ACT_UPDATE_SPACE_RSVT_STATUS]({ }, params) {
      return new Promise(resolve => {
        ApiService.put(`/v1/app/space/rsvt/updateStatus`, params, true).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_RSVT_SCHD_LIST]({}, {roomId, params}){
      return new Promise(resolve => {
        ApiService.query(`/v1/app/space/rsvt/room/${roomId}/schds`, params).then(response => resolve(response));
      });
    },
    [ACT_GET_SPACE_RSVT_CMPY_CHECK]({}, params){
      return new Promise(resolve => {
        ApiService.query('/v1/app/space/rsvt/cmpyValidation', params).then(response => resolve(response));
      });
    },
    [ACT_INSERT_SPACE_RSVT_INFO]({}, params){
      return new Promise(resolve => {
        ApiService.post('/v1/app/space/rsvt/create', params).then(response => resolve(response));
      });
    },
    [ACT_UPLOAD_SPACE_RSVT_IDENTITY]({}, files){
      return new Promise(resolve => {
        ApiService.upload('/v1/app/space/rsvt/idnty', files).then(response => resolve(response));
      });
    },
  }
  
  export const space = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  }
  